<template>
  <div class="translate-box">
    <div class="translate-source">
      <textarea name="" spellcheck="false" placeholder="input words">

      </textarea>
      <div class="edit-box">
        <i @click="translateLang" class="bi bi-globe"></i>
        <span>简体</span>
        <i class="bi bi-arrow-left-right"></i>
        <span>English</span>
      </div>
    </div>
    <div class="border"></div>
    <div class="translate-target">
      <div name="" spellcheck="false">
        ...
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'translate',
  data() {
    return {
      
    }
  },
  methods: {
    async translateLang() {
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
  .translate-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    .edit-box {
      cursor: pointer;
      padding: 0 20px;
      text-align: right;
      font-size: 12px;
      i {
        padding: 2px 4px;
      }
      .bi-arrow-left-right:hover {
        background-color: rgb(226, 226, 226);
      }
    }
    .border {
      margin: 10px 0;
      height: 1px;
      width: 100%;
      background-color: silver;
    }
    .translate-source {
      display: flex;
      flex-direction: column;
    }
    .translate-source, .translate-target {
      flex: 1;
      text-align: left;
      color: rgba(0,0,0,.87);
      font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    }
    .translate-target {
      background: #f5f5f5;
      font-size: 20px;
    }
    .translate-source textarea {
      flex: 1;
      font-size: 16px;
    }
    .translate-source textarea, .translate-target div {
      padding: 10px;
      line-height: 32px;
      box-sizing: border-box;
      outline: none;
      border-top: 0;
      background: transparent;
      border: none;
      resize: none;
      white-space: pre-wrap;
    }
  }
  </style>
    